<script>
import draggable from 'vuedraggable';
import {
    BAvatar,
    BButton,
    BCard,
    BCardText,
    BCol,
    BFormFile,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BInputGroup,
    BListGroupItem,
    BModal,
    BRow
} from 'bootstrap-vue';
import api from '@/services/api';
import UploadImage from '@/components/ui/UploadImage';
import { checkLang, sortItems, updateObjectToNew } from '@/util/helper';
import TableActionBtns from '@/components/TableActionBtns';
import CRUDPageMixin from '@/mixins/CRUDPageMixin';
import PageTitle from '@/components/PageTitle';
import ProgressLinear from '@/components/ui/ProgressLinear/ProgressLinear';

export default {
    name: 'Guides',
    components: {
        ProgressLinear,
        PageTitle,
        TableActionBtns,
        UploadImage,
        draggable,
        BListGroupItem,
        BButton,
        BModal,
        BInputGroup,
        BFormInput,
        BCardText,
        BAvatar,
        BFormTextarea,
        BRow,
        BCol,
        BFormGroup,
        BFormFile,
        BCard
    },
    mixins: [CRUDPageMixin],
    data: () => ({
        guides: [],
        dataForModal: {
            title: {
                uz: '',
                ru: '',
                en: ''
            },
            body: {
                uz: '',
                ru: '',
                en: ''
            },
            image: null,
            position: null
        },
        requestPending: true
    }),
    methods: {
        checkLang,
        async getGuides() {
            this.requestPending = true;
            const { data } = await api.guides.get();
            this.guides = data;
            this.sortGuidesByPosition();
            this.requestPending = false;
        },

        async submitModal() {
            this.$refs.createUpdateModal.validate().then(async (success) => {
                if (success) {
                    let formData = new FormData();

                    if (typeof this.dataForModal.image === 'object') {
                        formData.append('image', this.dataForModal.image);
                    }
                    Object.keys(this.dataForModal.title).forEach((item) => {
                        formData.append(`title[${item}]`, this.dataForModal.title[item]);
                    });
                    Object.keys(this.dataForModal.body).forEach((item) => {
                        formData.append(`body[${item}]`, this.dataForModal.body[item]);
                    });
                    formData.append('position', this.dataForModal.position);

                    const config = {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    };

                    try {
                        if (this.updatingItem) {
                            await this.updateGuide(formData, config);
                        } else {
                            await this.createGuide(formData, config);
                        }

                        this.sortGuidesByPosition();
                        this.isOpenModal = false;
                    } catch (error) {
                        this.$toast.error(this.$t('error.smth'));
                    }
                }
            });
        },

        async createGuide(formData, config) {
            const { data } = await api.guides.post(formData, config);
            this.guides.unshift(data);
            this.$toast.success(this.$t('success.added'));
        },

        async updateGuide(formData, config) {
            const { data } = await api.guides.updateByPost(this.updatingItem.id, formData, config);
            this.guides.forEach((guide) => {
                if (guide.id === this.updatingItem.id) {
                    updateObjectToNew(guide, data);
                }
            });
            this.$toast.success(this.$t('success.updated.title'));
        },

        async updatePosition({ moved }) {
            const id = moved.element.id;
            const position = ++moved.newIndex;

            await api.guides
                .put(id, { position })
                .then(() => {
                    this.$toast.success(this.$t('success.updated.position'));
                })
                .catch(() => {
                    this.$toast.error(this.$t('error.smth'));
                });
        },

        async deleteGuide(id, index) {
            await api.guides.delete(id);
            this.guides.splice(index, 1);
        },

        sortGuidesByPosition() {
            this.guides = sortItems(this.guides, 'position');
        }
    },
    mounted() {
        this.getGuides();
    }
};
</script>

<template>
    <div>
        <page-title>
            <template #title>
                {{ $t('navigation.guides') }}
            </template>
            <template #button v-if="hasAccess('guides', 'create')">
                <b-button v-b-modal.createUpdateModal variant="success" class="ml-auto">
                    {{ $t('button.add') }}
                    <feather-icon icon="PlusIcon" />
                </b-button>
            </template>
        </page-title>

        <div class="card">
            <progress-linear v-if="requestPending" />
            <div v-else class="informations-draggable-table">
                <table>
                    <thead>
                        <tr>
                            <th></th>
                            <th>
                                <div class="d-flex justify-content-center">#</div>
                            </th>
                            <th>
                                {{ $t('titles.logo') }}
                            </th>
                            <th>
                                {{ $t('titles.title') }}
                            </th>
                            <th>
                                {{ $t('titles.description') }}
                            </th>
                            <th></th>
                        </tr>
                    </thead>
                    <draggable
                        v-model="guides"
                        tag="tbody"
                        handle=".handle"
                        @change="updatePosition"
                    >
                        <tr v-for="(guide, index) in guides" :key="guide.index">
                            <td>
                                <div class="handle cursor-move">
                                    <feather-icon icon="AlignJustifyIcon" />
                                </div>
                            </td>
                            <td>
                                <div class="d-flex justify-content-center">
                                    {{ index + 1 }}
                                </div>
                            </td>
                            <td>
                                <b-avatar size="lg" :src="guide.image" />
                            </td>
                            <td>
                                <div class="d-flex flex-column ml-25" style="min-width: 300px">
                                    <b-card-text class="mb-0 font-weight-bold">
                                        {{ guide.title[localLang] }}
                                    </b-card-text>
                                </div>
                            </td>
                            <td>
                                <div class="d-flex flex-column ml-25" style="min-width: 300px">
                                    <b-card-text class="mb-0 font-weight-bold">
                                        {{ checkLang(guide.body) }}
                                    </b-card-text>
                                </div>
                            </td>
                            <td>
                                <table-action-btns
                                    :item="guide"
                                    :index="index"
                                    :delete-handler="deleteGuide"
                                    @openModal="openModalToUpdate"
                                />
                            </td>
                        </tr>
                    </draggable>
                </table>
            </div>
        </div>

        <b-modal
            v-model="isOpenModal"
            id="createUpdateModal"
            cancel-variant="danger"
            @hidden="onCloseModal"
            centered
            :hide-footer="false"
            :no-close-on-backdrop="false"
            size="sm"
            :title="$t('add.guide')"
        >
            <ValidationObserver ref="createUpdateModal">
                <b-row>
                    <!--    TITLE[uz]    -->
                    <b-col cols="12">
                        <label> {{ $t('titles.title') }} (uz) </label>
                        <ValidationProvider
                            :name="`${$t('titles.title')} (uz)`"
                            rules="required"
                            v-slot="{ errors }"
                        >
                            <b-form-group>
                                <b-form-textarea
                                    v-model="dataForModal.title.uz"
                                    size="lg"
                                    :placeholder="`${$t('enter.title')} (uz)`"
                                    :state="errors.length > 0 ? false : null"
                                />
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </b-col>

                    <!--    TITLE[ru]    -->
                    <b-col cols="12">
                        <label>{{ $t('titles.title') }} (ru)</label>
                        <ValidationProvider
                            :name="`${$t('titles.title')} (ru)`"
                            rules="required"
                            v-slot="{ errors }"
                        >
                            <b-form-group>
                                <b-form-textarea
                                    v-model="dataForModal.title.ru"
                                    size="lg"
                                    :placeholder="`${$t('enter.title')} (ru)`"
                                    :state="errors.length > 0 ? false : null"
                                />
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </b-col>

                    <!--    TITLE[en]    -->
                    <b-col cols="12">
                        <label>{{ $t('titles.title') }} (en)</label>
                        <ValidationProvider
                            :name="`${$t('titles.title')} (en)`"
                            rules="required"
                            v-slot="{ errors }"
                        >
                            <b-form-group>
                                <b-form-textarea
                                    v-model="dataForModal.title.en"
                                    size="lg"
                                    :placeholder="`${$t('enter.title')} (en)`"
                                    :state="errors.length > 0 ? false : null"
                                />
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </b-col>

                    <!--    body[uz]    -->
                    <b-col cols="12">
                        <label>{{ $t('titles.description') }} (uz)</label>
                        <ValidationProvider
                            :name="`${$t('titles.description')} (uz)`"
                            rules="required"
                            v-slot="{ errors }"
                        >
                            <b-form-group>
                                <b-form-textarea
                                    v-model="dataForModal.body.uz"
                                    size="lg"
                                    :placeholder="`${$t('enter.description')} (uz)`"
                                    :state="errors.length > 0 ? false : null"
                                />
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </b-col>

                    <!--    body[ru]    -->
                    <b-col cols="12">
                        <label>{{ $t('titles.description') }} (ru)</label>
                        <ValidationProvider
                            :name="`${$t('titles.description')} (ru)`"
                            rules="required"
                            v-slot="{ errors }"
                        >
                            <b-form-group>
                                <b-form-textarea
                                    v-model="dataForModal.body.ru"
                                    size="lg"
                                    :placeholder="`${$t('enter.description')} (ru)`"
                                    :state="errors.length > 0 ? false : null"
                                />
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </b-col>

                    <!--    body[en]    -->
                    <b-col cols="12">
                        <label>{{ $t('titles.description') }} (en)</label>
                        <ValidationProvider
                            :name="`${$t('titles.description')} (en)`"
                            rules="required"
                            v-slot="{ errors }"
                        >
                            <b-form-group>
                                <b-form-textarea
                                    v-model="dataForModal.body.en"
                                    size="lg"
                                    :placeholder="`${$t('enter.description')} (en)`"
                                    :state="errors.length > 0 ? false : null"
                                />
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </b-col>

                    <!--    Image    -->
                    <b-col cols="12">
                        <label for="guideImage">
                            {{ $t('titles.picture') }}
                        </label>
                        <ValidationProvider
                            :name="$t('titles.picture')"
                            rules="required"
                            v-slot="{ errors }"
                        >
                            <b-form-group>
                                <upload-image
                                    v-model="dataForModal.image"
                                    id="guideImage"
                                    :error="errors.length > 0 ? true : null"
                                />
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </b-col>

                    <!--    Position    -->
                    <b-col cols="12">
                        <label>
                            {{ $t('titles.position') }}
                        </label>
                        <ValidationProvider
                            :name="$t('titles.position')"
                            rules="required"
                            v-slot="{ errors }"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.position"
                                    size="lg"
                                    :placeholder="$t('enter.position')"
                                    :state="errors.length > 0 ? false : null"
                                />
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </b-col>
                </b-row>
            </ValidationObserver>
            <template #modal-footer>
                <b-button @click="submitModal" variant="success">
                    {{ modalSubmitBtnText }}
                </b-button>
            </template>
        </b-modal>
    </div>
</template>
