<script>
const ALLOWED_IMAGE_TYPES = 'image/jpg, image/jpeg, image/png, image/bmp';
const DEFAULT_AVATAR = 'img/icons/camera.svg';

export default {
    name: 'UploadImage',
    props: {
        value: {
            type: [String, File],
            default: null
        },
        id: {
            type: [String, Number],
            default: new Date().valueOf()
        },
        error: Boolean
    },
    data: () => ({
        ALLOWED_IMAGE_TYPES,
        uploadedImage: DEFAULT_AVATAR
    }),
    methods: {
        change(event) {
            const file = event.target.files[0];
            this.uploadedImage = URL.createObjectURL(file);
            this.$emit('input', file);
        },

        openInputFile() {
            this.$refs.input.click();
        }
    },
    mounted() {
        if (this.value) {
            this.uploadedImage = this.value;
        }
    }
};
</script>

<template>
    <div
        :class="[
            'upload-image',
            {
                'upload-image--is-invalid': error
            }
        ]"
    >
        <input
            ref="input"
            type="file"
            :accept="ALLOWED_IMAGE_TYPES"
            class="upload-image__input"
            :id="id"
            @change="change"
        />
        <label :for="id" class="upload-image__avatar">
            <img :src="uploadedImage" alt="" />
        </label>
        <div class="upload-image__desc">
            <label
                :for="id"
                tabindex="0"
                class="upload-image__label"
                @keydown.space="openInputFile"
            >
                {{ $t('button.uploadImage') }}
            </label>
            <i v-if="!value" class="upload-image__no-file">
                {{ $t('titles.noFileChosen') }}
            </i>
        </div>
    </div>
</template>

<style lang="scss" src="./UploadImage.scss" />
